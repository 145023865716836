.middle-section {
    padding-top: 10vh;
    min-height: 94vh;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}
